@font-face {
  font-family: 'payment';
  src: local('payment'), url('./fonts/Jost-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'opensans';
  src: local('opensans'), url('./fonts/open-sans.regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'opensans';
  src: local('opensans'), url('./fonts/open-sans.semibold.ttf') format('truetype');
  font-weight: bold;
}
* {
  font-family: 'opensans';
  font-weight: normal;
}
a {
  text-decoration: none!important;
}
.paymentfont {
  font-family: 'payment'!important;
}
img {
  width: 100%;
}
.lightshadow {
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.globalimgsize {
  width: 150px!important;
  height: 150px!important;
  border-radius: 80px;
}
.flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.blockbg {
  background-color: #ff6e40;
}
.bggrey {
  background-color: #eeeeee;
}
.bggreen {
  background-color: #64ffda!important;
}
.commonshadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
.pointer {
  cursor: pointer;
}
.officialbg {
  background-color: #6c757d!important;
}
.officialcolor {
  color: #f94144!important;
}
.lspace2 {
  letter-spacing: 2px;
}
.lspace3 {
  letter-spacing: 3px;
}
.lspace4 {
  letter-spacing: 4px;
}
.lspace5 {
  letter-spacing: 5px;
}
.shadow-1 {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px!important;
}

.c-thumbnail {
  width: 100%;
  height: 120px;
}
.wrap-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis
}

/* VERSION 2.0 */
.land-home {
  width: 100%;
  background-size: cover;
  height: 90.7vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
@media(max-width: 900px){
  .land-home {
    background-position: 75%;
  }
}


/* SIDEBAR */
.sidebar {
  width: 256px;
  height: 100%;
  position: fixed;
}
.content {
  margin-left: 250px!important;
  margin-top: 1em!important;
  margin-bottom: 1.5em;
}
@media(max-width: 768px){
  .sidebar {
    display: none;
    width: 0;
    position: inherit;
  }
  .content {
    width: 100%;
    margin-left: 0px!important;
  }
}
.sidebarIconColor {
  color: white;
}


/* PAGES */
.brand {
  text-align: left;
}
@media(max-width: 768px){
  .brand{
      text-align: center;
  }
}
.logo {
  width: 130px;
}
.bgGrey {
  background-color: #f5f5f5!important;
}
.bgOrange {
  background-color: #ef6c00!important;
}
.colorOrange {
  color: #ef6c00!important;
}

/* HEADER */
.navLink {
  text-align: end;
}
.navLink ul {
  margin-top: 20px;
}
.navLink ul li {
  display: inline;
  margin: 10px;
  font-size: 1.1em;
  color: white;
  padding: 0.3em;
}
.navLink a {
  color: white;
}
.navLink a:hover {
  color: black;
}

/* FOOTER */
.siteMap ul li {
  color: white;
}
@media(max-width: 768px){
  .footer {
      padding-bottom: 3em;
  }
}

/* HOME */
.landing{
  height: 90vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.registeredText {
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  border-radius: 10px;
  background-image: linear-gradient(120deg, #ef6c00, #ffd600);
}
.tagline {
  font-size: 4em;
}
@media(max-width: 768px){
  .tagline {
      font-size: 2.5em;
  }
}
.indexheader {
  position: fixed;
  top: 30px;
}
.indexheader .box {
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.95);
  z-index: +1;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.indexlinks ul li {
  display: inline;
}
.indexlinks ul {
  margin-top: 1.2em;
}
.indexlinks ul li a {
  color: white;
  padding: 0.4em;
  font-size: 0.9em;
}
.indexlinks ul li a:hover {
  color: #f94144;
}

/* COURSES */
.courses {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}